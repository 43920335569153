*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-tap-highlight-color: transparent;
}
/* IOS微信、UC浏览器阻止字体大小调整 */
body {
    -webkit-text-size-adjust: none !important;
}
body {
    -webkit-text-size-adjust: 100% !important;
}

input, textarea {
    outline: none;
}

a, a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}
body .vertical-scroll::-webkit-scrollbar {
    width: 3px;
    transition: all .3s;
}
body .horizontal-scroll::-webkit-scrollbar {
    height: 5px;
    transition: all .3s;
}

body *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .6);
    border-radius: 2px;
}
body *::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}
body *::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}
body *::-webkit-scrollbar-corner {
    background: rgba(255, 255, 255, .6);
}

.btn-default {
    border: 1px #0079FF solid;
    border-radius: 2px;
    color: #0079FF;
    background: transparent;
    cursor: pointer;
}
.btn-normal {
    border: 1px #0079FF solid;
    border-radius: 2px;
    color: #0079FF;
    background: transparent;
    cursor: pointer;
}

.btn-default:hover {
    border: 1px #008CFF solid;
    border-radius: 2px;
    color: #fff;
    background: #008CFF;
}
.btn-primary {
    border-radius: 2px;
    background: #0079FF;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
}
